import {RaceReg} from "../controllers/api/races";
import {RegStateType} from "../pages/register-race/additional/types";

const useRegCost = (
	race: RaceReg,
	state: RegStateType,
	purify: boolean = true
) => {
	const participants = state.participants.adults.length + state.participants.kids.length;
	const pack = race.packages.find((pack) => pack.id === state.packageId);
	const costPerP = pack ? pack.cost : 0;

	let cost = participants * costPerP;
	if(state.ship){
		cost += (state.withInMetro) ? 250 : 350;
	}

	if(state.print){
		cost += 100 * participants;
	}

	if(!purify) return cost;

	return new Intl.NumberFormat("en").format(cost);
}

export default useRegCost
