import {FormWrapperMargin} from "../../../components/form/wrapper/wrapperMargin";
import {Input} from "../../../components/form/input/input";
import {InputFile} from "../../../components/form/file-input/input";
import {RaceReg} from "../../../controllers/api/races";
import {RegStateType} from "../additional/types";
import React from "react";
import useRegCost from "../../../hooks/useRegCost";

type Props = {
	race: RaceReg,
	state: RegStateType,
	changeState: (value: RegStateType | ((prevState: RegStateType) => RegStateType)) => void,
	openPopup: (value: "gcash" | null) => void
}

export const RegisterRaceGCASH: React.FC<Props> = ({
	race,
	state,
	changeState,
	openPopup
}) => {
	const total = useRegCost(race, state);
	return (
		<FormWrapperMargin>
			<div className={"details-block"}>
				<p className={"label"}>GCash Details</p>
				<p className={"desc"}>You may deposit the amount of <b>Php{total}</b> to the following accounts:</p>
				<p>
					<span className={"link"} onClick={()=>openPopup("gcash")}>view qr code here</span>
				</p>
			</div>
			<Input
				label={"Sender name"}
				placeholder={"Enter name"}
				margin={"small"}
				value={state.senderName}
				onChange={
					(val) => {
						changeState((prev) => ({...prev, ...{senderName: val}}))
					}
				}
			/>
			<InputFile
				label={"Upload Gcash deposit slip(photo/screenshot)"}
				placeholder={"Upload deposit slip or screenshot"}
				value={state.screenshot}
				onChange={
					(val) => {
						changeState((prev) => ({...prev, ...{screenshot: val}}))
					}
				}
			/>
		</FormWrapperMargin>
	);
}