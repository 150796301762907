import './style.css';
import React from "react";
import {RaceDataType} from "../../../controllers/api/events";

type Props = {
	data: RaceDataType[]
}

export const RunData: React.FC<Props> = ({
	data
}) => {
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	const stepFormat = new Intl.NumberFormat("en");
	return (
		<div className={"run-data"}>
			<div className={"head"}>
				<div>Date</div>
				<div>Activity</div>
				<div>No. of steps</div>
			</div>
			{
				data.map((row, index) => {
					const date = new Date(parseInt(row.date)).toLocaleDateString('en-US', options);
					const steps = stepFormat.format(row.steps);
					return(
						<div className={"row"} key={index}>
							<div>{date}</div>
							<div>{row.activity}</div>
							<div>{steps}</div>
						</div>
					)
				})
			}
		</div>
	);
}