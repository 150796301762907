import '../style.css';
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import upload from "../../../assets/upload.svg"
import useNotify from "../../../hooks/useNotify";

type Props = {
	label: string,
	placeholder: string,
	value: any,
	onChange: (arg0: any) => void
}

export const InputFile: React.FC<Props> = ({
	label,
	placeholder,
	value,
	onChange
}) => {
	const notify = useNotify();
	const maxFileSize = 3 * 1024 * 1024;

	const changeHandler = (event: any) => {
		if(!event.target.files || !event.target.files[0]) return;
		const file = event.target.files[0];
		if(file.size > maxFileSize){
			notify.warning("File too large!");
			onChange(null);
			return;
		}
		event.target.value = null;
		onChange(file);
	}

	const id: string = `file${(new Date()).getTime()}`;

	const isPlaceholder = (value === null);
	const text = isPlaceholder ? placeholder : "File selected";

	return (
		<div className={"input-block"}>
			<span className={"label"}>{label}</span>
			<label htmlFor={id} className={"input"} >
				<input type="file" name="file" id={id} onChange={changeHandler} accept="image/jpeg,image/png,image/gif"/>
				{
					isPlaceholder ? <span className={"placeholder"}>{text}</span> : <span>{text}</span>
				}
				<img className={"icon"} src={upload} alt={placeholder}/>
			</label>
		</div>
	);
}