import './style.css';
import React from "react";

type Props = {
	label: string,
	subText?: string,
	value: number,
	onChange: (arg0: number) => void
}

export const InputNumber: React.FC<Props> = ({
	label,
	subText,
	value,
	onChange
}) => {
	return (
		<div className={"input-number"}>
			<div className={"text"}>
				<p className={"label"}>{label}</p>
				{
					subText && <p className={"subtext"}>{subText}</p>
				}
			</div>
			<div className={"counter"}>
				<div
					className={"button button_minus"}
					onClick={() => onChange(Math.max(0, value - 1))}
				>
					<svg width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.625 2.75H1.875C1.17188 2.75 0.625 2.20312 0.625 1.5C0.625 0.835938 1.17188 0.25 1.875 0.25H15.625C16.2891 0.25 16.875 0.835938 16.875 1.5C16.875 2.20312 16.2891 2.75 15.625 2.75Z" fill="#252B42"/>
					</svg>
				</div>
				<div className={"value"}>{value}</div>
				<div
					className={"button button_plus"}
					onClick={() => onChange(value+1)}
				>
					<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M16.875 8.5C16.875 9.20312 16.2891 9.78906 15.625 9.78906H10V15.4141C10 16.0781 9.41406 16.625 8.75 16.625C8.04688 16.625 7.5 16.0781 7.5 15.4141V9.78906H1.875C1.17188 9.78906 0.625 9.20312 0.625 8.5C0.625 7.83594 1.17188 7.28906 1.875 7.28906H7.5V1.66406C7.5 0.960938 8.04688 0.375 8.75 0.375C9.41406 0.375 10 0.960938 10 1.66406V7.28906H15.625C16.2891 7.25 16.875 7.83594 16.875 8.5Z" fill="white"/>
					</svg>
				</div>
			</div>
		</div>
	);
}