import { Outlet } from "react-router-dom";
import React, {useEffect, useState} from "react";
// @ts-ignore
import { SnackbarProvider } from 'snackfy';
import {useAppDispatch} from "./redux/hooks";
import {getUser} from "./redux/reducers/account";
import {paypalClientId} from "./config";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";

export type DeviceType = 'mobile' | 'desktop';
export const DeviceTypeContext = React.createContext<DeviceType>('desktop');

function App() {
	const dispatch = useAppDispatch();
	const [deviceType, setDeviceType] = useState<DeviceType>(() => checkDeviceType());

	function checkDeviceType() : DeviceType {
		if(window.innerWidth <= 768) return 'mobile';
		return 'desktop'
	}

	function updateDeviceType() {
		setDeviceType(checkDeviceType());
	}

	useEffect(() => {
		window.addEventListener('resize', updateDeviceType);
		dispatch(getUser());
		return () => {
			window.removeEventListener('resize', updateDeviceType);
		}
	}, []);

	return (
		<DeviceTypeContext.Provider value={deviceType}>
			<PayPalScriptProvider options={{ "client-id": paypalClientId, "currency": "PHP" }}>
				<SnackbarProvider maxSnacks={2}>
					<Outlet />
				</SnackbarProvider>
			</PayPalScriptProvider>
		</DeviceTypeContext.Provider>
	);
}

export default App;
