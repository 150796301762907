import {Profile} from "../../components/profile/profile";
import {RaceList} from "../../components/races/list";
import useFetch from "../../hooks/useFetch";
import {getMyRaces, getNewRaces} from "../../controllers/api/races";

export default function Dashboard(){
	const myRaces = useFetch(getMyRaces, []);
	const newRaces = useFetch(getNewRaces, []);

	return (
		<>
			<Profile/>
			<RaceList label={"My Race"} races={myRaces} hasParticipate={true}/>
			<RaceList label={"Select Race"} races={newRaces} hasParticipate={false}/>
		</>
	);
}