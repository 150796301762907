import React from "react";
import {Selector, SelectorItemType} from "../../../components/form/selector/input";
import {RowTitle} from "../../../components/row-title/title";
import {FormWrapper} from "../../../components/form/wrapper/wrapper";
import {Input} from "../../../components/form/input/input";
import {InputDate} from "../../../components/form/date-picker/input";
import {FormWrapperMargin} from "../../../components/form/wrapper/wrapperMargin";
import {AdultParticipateType} from "../additional/types";

type Props = {
	num: number,
	value: AdultParticipateType,
	changeValue: (index: number, value: AdultParticipateType) => void,
	openPopup: (value: "adult" | "kid" | null) => void
}

export const Adult: React.FC<Props> = ({
	num,
	value,
	changeValue,
	openPopup
}) => {
	const shirtSizes: SelectorItemType[] = [
		{key: "S",value: "S"},
		{key: "M", value: "M"},
		{key: "L", value: "L"},
		{key: "XL",value: "XL"},
		{key: "2XL",value: "2XL"},
	];

	return (
		<>
			<RowTitle text={`Adult ${num+1}`} icon={"user"}/>
			<FormWrapper>
				<Input
					label={"Name"}
					placeholder={"Enter name"}
					value={value.name}
					onChange={(val) => {
						changeValue(num, ({...value, ...{name: val}}))
					}}
				/>
				<InputDate
					label={"Birthdate"}
					placeholder={"mm/dd/yy"}
					value={value.birthdate}
					onChange={(val) => {
						changeValue(num, ({...value, ...{birthdate: val}}))
					}}
				/>
				<Input
					label={"Province"}
					placeholder={"Enter province"}
					value={value.city}
					onChange={(val) => {
						changeValue(num, ({...value, ...{city: val}}))
					}}
				/>
				<Input
					label={"Mobile no."}
					type={"tel"}
					placeholder={"Enter mobile no."}
					value={value.phone}
					onChange={(val) => {
						const newVal = val.replace(/\D/g,'');
						changeValue(num, ({...value, ...{phone: newVal}}))
					}}
				/>
			</FormWrapper>
			<FormWrapperMargin>
				<p className={"titleText2"}>Shirt size</p>
				<Selector
					items={shirtSizes}
					type={"default"}
					margin={"small"}
					value={value.shirtSize}
					onChange={(val) => {
						changeValue(num, ({...value, ...{shirtSize: val}}))
					}}
				/>
				<p className={"note"}>Shirt sizes guide view <span className={"link"} onClick={()=>openPopup("adult")}>here</span></p>
			</FormWrapperMargin>
		</>
	);
}