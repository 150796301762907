import './style.css';
import React from "react";
import {EventType} from "../../../controllers/api/events";
import {daysRemaining} from "../../../controllers/date";

type Props = {
	race: EventType
}

export const RaceData: React.FC<Props> = ({
	race
}) => {
	const steps = new Intl.NumberFormat("en").format(Math.max(0, race.steps - race.distance));
	const remainingDays = daysRemaining(race.end_date);
	const category = `${Math.trunc(race.steps / 1000)}K steps`;
	const teamMembers = race.participants.adults.length + race.participants.kids.length;

	const isMultiplyDays = remainingDays !== 1;
	return (
		<div className={"race-data"}>
			<div className={"block"}>
				<p className={"title"}>Remaining steps</p>
				<p className={"value"}>{steps}</p>
			</div>
			<div className={"block"}>
				<p className={"title"}>Race category</p>
				<p className={"value"}>{category}</p>
			</div>
			<div className={"block"}>
				<p className={"title"}>No. of days remaining</p>
				<p className={"value"}>{remainingDays} {isMultiplyDays ? 'days' : 'day'}</p>
			</div>
			<div className={"block"}>
				<p className={"title"}>Team members</p>
				<p className={"value"}>{teamMembers}</p>
			</div>
		</div>
	);
}