import '../style.css';
import React from "react";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
	text: string,
	link: string
}

export const FormLink: React.FC<Props> = ({
	text,
	link
}) => {
	return (
		<div className={"input-block"}>
			<div className={"form-link"}>
				<div>
					<a href={link} target={"_blank"}>{text}</a>
				</div>
			</div>
		</div>
	);
}