import '../style.css';
import React from "react";

type Props = {
	label: string,
	placeholder: string,
	margin?: boolean,
	value: string,
	onChange: (arg0: string) => void
}

export const Textarea: React.FC<Props> = ({
	label,
	placeholder,
	margin = false,
	value,
	onChange
}) => {
	return (
		<div className={`input-block _textarea ${margin && "_margin"}`}>
			<span className={"label"}>{label}</span>
			<textarea
				placeholder={placeholder}
				value={value}
				onChange={(e) => onChange(e.target.value)}
			/>
		</div>
	);
}