import '../style.css';
import React from "react";

type Props = {
	children?: React.ReactNode;
	margin?: boolean;
}

export const FormWrapper: React.FC<Props> = ({
	children,
	margin= true
}) => {
	return (
		<div className={`form ${margin && '_margin'}`}>
			{children}
		</div>
	);
}