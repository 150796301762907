import React from "react";
import {Selector, SelectorItemType} from "../../../components/form/selector/input";
import {FormWrapperMargin} from "../../../components/form/wrapper/wrapperMargin";
import {RegStateType} from "../additional/types";
import {Checkbox} from "../../../components/form/checkbox/input";
import {Textarea} from "../../../components/form/textarea";

type Props = {
	value: RegStateType,
	changeState: (value: RegStateType | ((prevState: RegStateType) => RegStateType)) => void,
}

export const Ship: React.FC<Props> = ({
	value,
	changeState
}) => {
	const withInMetro: SelectorItemType[] = [
		{
			key: 1,
			value: "Yes",
		},
		{
			key: 0,
			value: "No",
		}
	];

	const wtnMetro = (value.withInMetro) ? 1 : 0;
	const shippingFee = (value.withInMetro) ? 250 : 350;

	return (
		<>
			<p className={"titleText1"}>Claiming my kits (optional)</p>
			<p className={"descriptionText"}>Kits may be claimed at Kamias Rd, Quezon City starting June 15. If you want it delivered click the checkbox below. Shipping rates for Metro Manila (P250/ 4 kits) and Provincial (P350 / 4 kits). We will contact you if additional shipping fee is required.</p>
			<FormWrapperMargin>
				<Checkbox
					value={value.ship}
					onChange={
						(val) => {
							changeState((prev) => ({...prev, ...{ship: val}}))
						}
					}
				>
					I want my kits delivered
				</Checkbox>
				{
					value.ship && (
						<>
							<p className={"titleText2"}>Are you within  Metro Manila</p>
							<Selector
								items={withInMetro}
								type={"default"}
								margin={"small"}
								value={wtnMetro}
								onChange={
									(val) => {
										changeState((prev) => ({...prev, ...{withInMetro: !!val}}))
									}
								}
							/>
							<p className={"accent"}>Shipping Fee:  +P{shippingFee}</p>
							<Textarea
								label={"Complete delivery address"}
								placeholder={"Enter your complete address"}
								margin={true}
								value={value.shipAddress}
								onChange={
									(val) => {
										changeState((prev) => ({...prev, ...{shipAddress: val}}))
									}
								}
							/>
						</>
					)
				}
			</FormWrapperMargin>
		</>
	);
}