import {Banner} from "../../components/banner/banner";
import React, {useState} from "react";
import {RaceReg} from "../../controllers/api/races";
import {RegStateType} from "./additional/types";
import {RegisterRaceStep1} from "./step1";
import {RegisterRaceStep2} from "./step2";
import {RegisterRaceStep3} from "./step3";
import {RegisterRaceStep4} from "./step4";
import RegisterRaceStep5 from "./step5";

type Props = {
	race: RaceReg
}

export const RegisterRace: React.FC<Props> = ({
	race
}) => {
	const [step, setStep] = useState(1);
	const [state, setState] = useState<RegStateType>({
		packageId: race.packages[0].id || null,
		adults: 1,
		kids: 1,
		participants: {
			adults: [],
			kids: []
		},
		ship: false,
		withInMetro: true,
		shipAddress: '',
		print: false,
		printText: '',
		paymentMethod: 1,
		screenshot: null,
		senderName: ""
	})

	return (
		<>
			<Banner img={race.banner} img2={race.banner2} img3={race.banner3}/>
			{
				step === 1 && <RegisterRaceStep1 race={race} state={state} changeState={setState} changeStep={setStep}/>
			}
			{
				step === 2 && <RegisterRaceStep2 race={race} state={state} changeState={setState} changeStep={setStep}/>
			}
			{
				step === 3 && <RegisterRaceStep3 race={race} state={state} changeState={setState} changeStep={setStep}/>
			}
			{
				step === 4 && <RegisterRaceStep4 race={race} state={state} changeStep={setStep}/>
			}
			{
				step === 5 && <RegisterRaceStep5/>
			}
		</>
	);
}