import {FormWrapperMargin} from "../../../components/form/wrapper/wrapperMargin";
import {InputFile} from "../../../components/form/file-input/input";
import {RaceReg} from "../../../controllers/api/races";
import {RegStateType} from "../additional/types";
import React from "react";
import useRegCost from "../../../hooks/useRegCost";

type Props = {
	race: RaceReg,
	state: RegStateType,
	changeState: (value: RegStateType | ((prevState: RegStateType) => RegStateType)) => void,
}

export const RegisterRaceBankTransfer: React.FC<Props> = ({
	race,
	state,
	changeState
}) => {
	const total = useRegCost(race, state);
	return (
		<FormWrapperMargin>
			<div className={"details-block"}>
				<p className={"label"}>Bank Transfer</p>
				<p className={"desc"}>You may deposit the amount of <b>Php{total}</b> to the following accounts:</p>
				<p>
					<b>
						UnionBank<br/>
						Metro Advantage Media Marketing Services Corp.
					</b><br/>
					Acct# 002480005581
				</p>
			</div>
			<InputFile
				label={"Upload payment deposit slip(photo/screenshot)"}
				placeholder={"Upload deposit slip or screenshot"}
				value={state.screenshot}
				onChange={
					(val) => {
						changeState((prev) => ({...prev, ...{screenshot: val}}))
					}
				}
			/>
		</FormWrapperMargin>
	);
}