import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { RootState } from '../store';
import {getInfo, logout} from "../../controllers/api/user";

export interface AccountState {
  isAuth: boolean | null,
  id: string,
  name: string,
  email: string,
  address: string,
  phone: string,
  img: string | null,
  google_link: string | null,
  facebook_link: string | null,
}

const initialState: AccountState = {
  isAuth: null,
  id: '',
  name: '',
  email: '',
  address: '',
  phone: '',
  img: null,
  google_link: '',
  facebook_link: '',
};

export const getUser = createAsyncThunk(
    'account/auth',
    async () => {
      const response = await getInfo();
      if(response.hasOwnProperty("auth")){
        if(response.auth){
          return {
            isAuth: true,
            id: response.id,
            name: response.name,
            email: response.email,
            address: response.address,
            phone: response.phone,
            img: response.img,
            google_link: null,
            facebook_link: null
          };
        }

        return Object.assign({}, initialState, {
          isAuth: false,
          google_link: response.google_link,
          facebook_link: response.facebook_link,
        });
      }

      return initialState;
    }
);

export const logoutAction = createAsyncThunk(
    'account/logout',
    async () => {
      const response = await logout();
      if(response.hasOwnProperty("success")){
        return Object.assign({}, initialState, {
          isAuth: false,
          google_link: response.google_link,
          facebook_link: response.facebook_link,
        });
      }
    }
);

export const account = createSlice({
  name: 'account',
  initialState,

  reducers: {
    // logout: () => {
    //   return initialState;
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      return action.payload
    })
    .addCase(logoutAction.fulfilled, (state, action) => {
        return action.payload
    })
  },
});

// export const { logout } = account.actions;

export const selectAccount = (state: RootState) => state.account;

export default account.reducer;
