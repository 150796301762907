import {Wrapper} from "../components/wrapper/wrapper";
import {useNavigate, useParams} from "react-router-dom";
import useFetch from "../hooks/useFetch";
import {getRaceReg} from "../controllers/api/races";
import {RegisterRace} from "../pages/register-race";

export default function RaceReg(){
	let navigate = useNavigate();
	const { id } = useParams();
	const race = useFetch(getRaceReg, undefined, id);

	if(race === null){
		navigate("/");
	}

	if(!race){
		return <></>
	}

	return (
		<Wrapper>
			<RegisterRace race={race}/>
		</Wrapper>
	);
}