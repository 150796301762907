import React from "react";
import './style.css';
import pen from '../../assets/pen.svg'
import {useAppSelector} from "../../redux/hooks";
import {selectAccount} from "../../redux/reducers/account";
import { Link } from "react-router-dom";

type Props = {
	hasEdit?: boolean,
}

export const Profile: React.FC<Props> = ({
	hasEdit= true,
}) => {
	const account = useAppSelector(selectAccount);

	return (
		<div className={"container profile-block"}>
			<div className={"account"}>
				<div className={"photo"}>
					<img src={"/images/profile.jpg"} alt={"profile"}/>
				</div>
				<div className={"info"}>
					<p className={"name"}>{account.name}</p>
					<p className={"email"}>{account.email}</p>
					<p className={"id"}>#{account.id}</p>
				</div>
			</div>
			{
				hasEdit && (
					<Link to="/profile" className={"edit"}>
						<img src={pen} alt={"edit"}/>
						<span>Edit</span>
					</Link>
				)
			}
		</div>
	);
}