import {makeRequest} from "../apiRequest";
import {AdultParticipateType, KidParticipateType} from "../../pages/register-race/additional/types";

export type RaceDataType = {
	steps: number,
	activity: string,
	date: string
}

export type EventType = {
	id: number,
	name: string,
	description: string,
	start_date: Date,
	end_date: Date,
	deadline: Date,
	logo: string | null,
	banner: string | null,
	banner2: string | null,
	banner3: string | null,
	steps: number,
	participate_id: number,
	payment: boolean,
	method: string,
	participants: {
		adults: AdultParticipateType[],
		kids: KidParticipateType[]
	},
	distance: number,
	sum: number,
	race_data: RaceDataType[]
}

export async function getEvent(id: string | undefined): Promise<EventType | null>{
	if(!id) return null;
	const response = await makeRequest(`event/get/${id}`);
	if(!response.hasOwnProperty("auth") || !response.auth || !response.hasOwnProperty("id") ) return null;
	return prepareEvent(response);
}

export async function confirmPayment(participation_id: number, order: string): Promise<any> {
	const data = {
		participation_id: participation_id,
		order: order
	}
	const response = await makeRequest('event/payment/', data);
	if(!response.hasOwnProperty("success") ) return null;
	return response;
}

export async function addActivity(participation_id: number, activity: string, date: Date, steps: number, file: any): Promise<any> {
	const formData = new FormData();
	formData.append('File', file);
	const data = {
		participation_id: participation_id,
		activity: activity,
		date: date.getTime(),
		steps: steps
	}
	formData.append('data', JSON.stringify(data));
	const response = await makeRequest('event/activity/', formData, true);
	if(!response.hasOwnProperty("success") ) return null;
	return response;
}

function prepareEvent(response: any): EventType {
	return {
		id: parseInt(response['id']),
		name: response.name,
		description: response.description,
		start_date: new Date(response.start_date),
		end_date: new Date(response.end_date),
		deadline: new Date(response.deadline),
		logo: (response['logo'] ? response['logo'] : null),
		banner: (response['banner'] ? response['banner'] : null),
		banner2: (response['banner2'] ? response['banner2'] : null),
		banner3: (response['banner3'] ? response['banner3'] : null),
		steps: parseInt(response['steps']),
		participate_id: parseInt(response['participate_id']),
		payment: response['payment'] === "1",
		method: response['method'],
		participants: response['participants'],
		distance: parseInt(response['distance']),
		sum: parseInt(response['sum']),
		race_data: response['race_data']
	}
}