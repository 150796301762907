import '../style.css';
import React from "react";

type Props = {
	children?: React.ReactNode;
}

export const FormButtons: React.FC<Props> = ({
	children
}) => {
	return (
		<div className={"form-buttons"}>
			<div className={"box"}>
				{children}
			</div>
		</div>
	);
}