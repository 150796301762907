import {RowTitle} from "../../components/row-title/title";
import {Button} from "../../components/form/buttons/button";
import {FormButtons} from "../../components/form/buttons/box";
import {FormComplete} from "../../components/form/wrapper/wrapperComplete";

export default function RegisterRaceStep5(){
	return (
		<div className={"container"}>
			<FormComplete>
				<RowTitle text={"Registration Complete"} icon={'check'}/>
				<p className={"descriptionText"}>
					Please wait for confirmation in your registered email address.<br/>
					<br/>
					Mark in your calendars: challenge runs from May 1 – 31, 2022<br/>
					<br/>
					Follow <a href={"https://www.facebook.com/sunlifeph"} target={"_blank"}><b>Sun Life PH</b></a> and <a href={"https://www.facebook.com/sunpiology"} target={"_blank"}><b>Sunpiology</b></a> for updates<br/>
					<br/>
					Check out our social media pages for promos and extra challenges you can join<br/>
					<br/><br/>
					For any concerns, you may email us at<br/>
					<b>sunpiologyvr@gmail.com</b>
				</p>
			</FormComplete>
			<FormButtons>
				<Button label={"BACK TO DASHBOARD"} link={"/"}/>
			</FormButtons>
		</div>
	);
}