import '../style.css';
import React from "react";

type Props = {
	children?: React.ReactNode;
}

export const FormComplete: React.FC<Props> = ({
	children
}) => {
	return (
		<div className={"form-complete"}>
			{children}
		</div>
	);
}