import {Input} from "../../components/form/input/input";
import {Button} from "../../components/form/buttons/button";
import {FormButtons} from "../../components/form/buttons/box";
import {FormWrapper} from "../../components/form/wrapper/wrapper";
import {Profile} from "../../components/profile/profile";
import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {getUser, selectAccount} from "../../redux/reducers/account";
import {update} from "../../controllers/api/user";
import useNotify from "../../hooks/useNotify";

export default function ProfilePage(){
	const dispatch = useAppDispatch();
	const account = useAppSelector(selectAccount);
	const notify = useNotify();

	const [name, setName] = useState(account.name);
	const [address, setAddress] = useState(account.address);
	const [phone, setPhone] = useState(account.phone);

	async function updateHandler() {
		const response = await update(name, address, phone);

		if(!response.hasOwnProperty("success")){
			notify.warning("Something went wrong");
			return;
		}

		if(!response.success){
			notify.warning(response.error);
			return;
		}

		notify.success("Profile updated successfully");
		dispatch(getUser());
	}

	return (
		<>
			<Profile hasEdit={false}/>
			<div className={"container"}>
				<FormWrapper>
					<Input placeholder={"Enter name"} label={"Name"} value={name} onChange={setName}/>
					<Input placeholder={"Enter email"} label={"Email"} type={"email"} value={account.email}/>
					<Input placeholder={"Enter address"} label={"Address"} value={address} onChange={setAddress}/>
					<Input
						placeholder={"Enter mobile no."}
						label={"Mobile no."}
						type={"tel"}
						value={phone}
						onChange={
							(value) => {
								const newVal = value.replace(/\D/g,'');
								setPhone(newVal);
							}
						}
					/>
				</FormWrapper>
				<FormButtons>
					<Button label={"BACK"} link={"/"}/>
					<Button label={"save"} type={"primary"} handler={updateHandler}/>
				</FormButtons>
			</div>
		</>
	);
}