import './style.css';
import checkmark from '../../../assets/checkmark.svg';
import React from "react";

type Props = {
	children?: React.ReactNode,
	value: boolean,
	onChange: (arg0: boolean) => void
}

export const Checkbox: React.FC<Props> = ({
	children,
	value,
	onChange
}) => {
	return (
		<div className={"checkbox-block"}>
			<div className={`checkbox ${value && 'active'}`} onClick={() => onChange(!value)}>
				{
					value && <img src={checkmark} alt={"checked"}/>
				}
			</div>
			<span className={"label"}>{children}</span>
		</div>
	);
}