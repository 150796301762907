import './style.css';
import React from "react";
import { Link } from "react-router-dom";

type Props = {
	type?: "default" | "primary",
	label: string,
	handler?: () => void,
	link?: string,
}

export const Button: React.FC<Props> = ({
	type= "default",
	label,
    handler,
    link
}) => {
	if(link){
		return(
			<Link to={link} className={`btn btn-${type}`}>
				{label}
			</Link>
		);
	}

	return (
		<div
			className={`btn btn-${type}`}
			onClick={handler}
		>
			{label}
		</div>
	);
}