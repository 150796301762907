import {Wrapper} from "../components/wrapper/wrapper";
import {useNavigate, useParams} from "react-router-dom";
import {EventType, getEvent} from "../controllers/api/events";
import {Race} from "../pages/race";
import {useEffect, useState} from "react";

export default function Event(){
	let navigate = useNavigate();
	const { id } = useParams();
	const [race, setRace] = useState<EventType | null>(null);

	async function getRace() {
		const response = await getEvent(id);
		if(response === null){
			navigate("/");
			return;
		}
		setRace(response);
	}

	useEffect(() => {
		getRace();
	}, []);

	if(race === null){
		return <></>
	}

	return (
		<Wrapper>
			<Race race={race} update={getRace}/>
		</Wrapper>
	);
}