import {ProgressBar} from "../../components/progress";
import {RowTitle} from "../../components/row-title/title";
import {Selector, SelectorItemType} from "../../components/form/selector/input";
import {FormWrapperMargin} from "../../components/form/wrapper/wrapperMargin";
import {Button} from "../../components/form/buttons/button";
import {FormButtons} from "../../components/form/buttons/box";
import {Checkbox} from "../../components/form/checkbox/input";
import {RegisterRaceGCASH} from "./step3/gcash";
import React, {useState} from "react";
import {RegisterRaceBankTransfer} from "./step3/bank-transfer";
import {RaceReg} from "../../controllers/api/races";
import {RegStateType} from "./additional/types";
import {TotalCost} from "./additional/cost";
import useNotify from "../../hooks/useNotify";
// @ts-ignore
import {Lightbox} from "react-modal-image";

type Props = {
	race: RaceReg,
	state: RegStateType,
	changeState: (value: RegStateType | ((prevState: RegStateType) => RegStateType)) => void,
	changeStep: (step: number) => void,
}

export const RegisterRaceStep3: React.FC<Props> = ({
	race,
	state,
	changeState,
	changeStep
}) => {
	const notify = useNotify();

	const [popup, setPopup] = useState<"gcash" | null>(null);
	const [ch1, setCh1] = useState(false);
	const [ch2, setCh2] = useState(false);

	let paymentMethods: SelectorItemType[] = [];
	if(race.transfer) paymentMethods.push({
		key: 1,
		value: "BANK TRANSFER",
	});
	if(race.paypal) paymentMethods.push({
		key: 2,
		value: "PAYPAL",
	});
	if(race.gcash) paymentMethods.push({
		key: 3,
		value: "GCASH",
	});

	function nextStep(){
		if(!ch1 || !ch2){
			notify.warning("Agreements are required");
			return;
		}

		if(state.paymentMethod === 1 || state.paymentMethod === 3){
			if(!state.screenshot){
				notify.warning("Payment deposit slip(photo/screenshot) is required");
				return;
			}
		}

		if(state.paymentMethod === 3 && !state.senderName){
			notify.warning("Sender name is required");
			return;
		}

		changeStep(4);
	}

	return (
		<div className={"container"}>
			<ProgressBar current={3} steps={4}/>
			<RowTitle text={"Select Payment Method"} icon={'payment'}/>
			<FormWrapperMargin>
				<Selector
					items={paymentMethods}
					type={"medium"}
					margin={"small"}
					value={state.paymentMethod}
					onChange={
						(val) => {
							changeState((prev) => ({...prev, ...{paymentMethod: val}}))
						}
					}
				/>
			</FormWrapperMargin>
			{
				state.paymentMethod === 1 && <RegisterRaceBankTransfer race={race} state={state} changeState={changeState}/>
			}
			{
				state.paymentMethod === 3 && <RegisterRaceGCASH race={race} state={state} changeState={changeState} openPopup={setPopup}/>
			}
			<FormWrapperMargin>
				<Checkbox
					value={ch1}
					onChange={setCh1}
				>
					I agree to the <a href={"https://sunpiology.com/terms-and-conditions"} target={"_blank"}>terms and conditions.</a>
				</Checkbox>
				<Checkbox
					value={ch2}
					onChange={setCh2}
				>
					By clicking REGISTER, you authorize Sun Life of Canada (Philippines) Inc. ("Company") to use and share your contact details and other personal data listed above to register you for the webinar and to contact you with marketing or promotional activities regarding financial, insurance products through phone calls, mail, email, SMS or any type of electronic message to be conducted by the Company and its related parties. You also consent to the webinar’s recording and agree that your [name/ image/ voice/messages], including any questions raised or opinions expressed, may be included. You agree such material may be re-broadcasted by the Company, at its sole discretion, even after the event for purposes of documentation. Your personal data shall be retained until expiration of the retention limit set by laws and regulations and the period set until destruction or disposal of records, unless withdrawn in writing or withheld due to changes in the information supplied by the Company. Please read the Company's privacy policy statement found in https://apps.sunlife.com.ph/onlineprivacy
				</Checkbox>
			</FormWrapperMargin>
			<TotalCost race={race} value={state}/>
			<FormButtons>
				<Button label={"BACK"} handler={() => changeStep(2)}/>
				<Button label={"NEXT"} type={"primary"} handler={nextStep}/>
			</FormButtons>
			{
				popup === "gcash" && (
					<Lightbox
						medium={"/images/gcash-qr.jpg"}
						alt="GCash QR"
						hideZoom={true}
						onClose={() => setPopup(null)}
					/>
				)
			}
		</div>
	);
}