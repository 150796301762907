import React from "react";
import {useAppSelector} from "../../../redux/hooks";
import {selectAccount} from "../../../redux/reducers/account";
import {Navigate, useLocation} from "react-router-dom";
import Loader from "../../../pages/loader";

type Props = {
	requireAuth?: boolean,
	children: JSX.Element
}

export const SecurityLayer: React.FC<Props> = ({
	requireAuth = false,
	children
}) => {
	const isAuth = useAppSelector(selectAccount).isAuth;
	let location = useLocation();

	if(isAuth === null){
		return <Loader/>
	}

	if(requireAuth){
		if(!isAuth) return <Navigate to="/login" state={{ from: location }} replace />;
	}
	else{
		if(isAuth) return <Navigate to="/" />;
	}

	return children;
}