import React from "react";
import './style.css';

type Props = {
	current: number;
	steps: number;
}

export const ProgressBar: React.FC<Props> = ({
	current,
	steps
}) => {

	let elements: React.ReactNode[] = [];

	const dot = (key: number, isActive: boolean) => <div className={`dot ${isActive && 'active'}`} key={key}/>;
	const line = (key: number) => <div className={'line'} key={key}/>;

	for(let i = 0; i < steps; i++){
		elements.push(dot(i, i < current));
		if(i < steps - 1){
			elements.push(line(steps + i));
		}
	}

	return (
		<div className={"progress-bar"}>
			{elements}
		</div>
	);
}