import './style.css';
import race from '../../assets/race.svg'
import family from '../../assets/family.svg'
import user from '../../assets/user.svg'
import kid from '../../assets/kid.svg'
import payment from '../../assets/payment.svg'
import check from '../../assets/check.svg'
import React from "react";

type Props = {
	text: string;
	icon?: 'race' | 'family' | 'user' | 'kid' | 'payment' | 'check' | 'none';
	type?: 'label' | 'tab';
}

export const RowTitle: React.FC<Props> = ({
	text,
	icon = "none",
	type= "label"
}) => {

	let iconLink = null;
	switch (icon) {
		case "race":
			iconLink = race;
			break;
		case "family":
			iconLink = family;
			break;
		case "user":
			iconLink = user;
			break;
		case "kid":
			iconLink = kid;
			break;
		case "payment":
			iconLink = payment;
			break;
		case "check":
			iconLink = check;
			break;
	}

	return (
		<div className={`row-title row-title_${type}`}>
			{
				iconLink && <img src={iconLink} alt={"icon"}/>
			}
			<span>{text}</span>
		</div>
	);
}