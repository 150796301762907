import './style.css';
import {RowTitle} from "../row-title/title";
import {RaceListItem} from "./item";
import {RaceListItemReg} from "./item-reg";
import {RacesItem} from "../../controllers/api/races";
import React from "react";

type Props = {
	label: string,
	races: RacesItem[],
	hasParticipate: boolean
}

export const RaceList: React.FC<Props> = ({
	label,
	races,
	hasParticipate
}) => {
	if(races.length === 0) return <></>

	return (
		<div className={"race-list container"}>
			<RowTitle text={label} icon={"race"}/>
			<div className={"list"}>
				{
					races.map((race) => {
						if(hasParticipate){
							return <RaceListItem key={race.id} race={race}/>
						}
						else{
							return <RaceListItemReg key={race.id} race={race}/>
						}
					})
				}
			</div>
		</div>
	);
}