import './style.css';
import React, {useEffect, useState} from "react";

type Props = {
	img: string | null
	img2?: string | null
	img3?: string | null
}

export const Banner: React.FC<Props> = ({
	img,
	img2,
	img3,
}) => {
	const [iter, setIter] = useState<number>(1);

	useEffect(() => {
		const interval = setInterval(() => {
			setIter((prev) => {
				let next = prev + 1;
				if(
					(next === 2 && !img2)
					|| (next === 3 && !img3)
					|| (next === 4)
				) next = 1;
				return next;
			});
		}, 5000);
		return () => clearInterval(interval);
	}, [])

	let src:string | null | undefined = "";
	switch (iter) {
		case 1:
			src = img;
			break;
		case 2:
			src = img2;
			break;
		case 3:
			src = img3;
			break;
	}

	return (
		<div className={"banner"}>
			{
				src && <img src={src} alt={"banner"}/>
			}
		</div>
	);
}