import './style.css';
import React from "react";

export type SelectorItemType = {
	key: string | number,
	value: string,
	subText?: string
}

type Props = {
	type?: 'default' | 'medium' | 'large',
	items: SelectorItemType[],
	margin?: 'small' | 'large',
	onChange: (arg0: any) => void,
	value: string | number | null
}

export const Selector: React.FC<Props> = ({
	type = 'default',
	items,
	margin = 'large',
	value,
	onChange
}) => {
	function clickHandler(key: string | number){
		if(!items.some((item) => item.key === key)) return;
		onChange(key);
	}

	return (
		<div className={`selector ${type !== 'default' && `_${type}`} _margin_${margin}`}>
			{
				items.map((item) => (
					<div
						key={item.key}
						className={`item ${item.key === value && 'active'}`}
						onClick={clickHandler.bind(null, item.key)}
					>
						<p>{item.value}</p>
						{
							(item.subText) && <p><b>{item.subText}</b></p>
						}
					</div>
				))
			}
		</div>
	);
}