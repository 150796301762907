import '../style.css';
import React from "react";

type Props = {
	type?: 'text' | 'number' | 'password' | 'email' | 'tel';
	label?: string,
	placeholder: string,
	margin?: 'mini' | 'small' | 'large' | false,
	value?: string,
	onChange?: (arg0: string) => void
}

export const Input: React.FC<Props> = ({
	type = 'text',
	label,
	placeholder,
	margin = false,
	value,
	onChange
}) => {
	return (
		<div className={`input-block ${margin && "_margin-"+margin}`}>
			{
				label && (<span className={"label"}>{label}</span>)
			}

			<input
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={(e) => onChange && onChange(e.target.value)}
				disabled={!onChange}
			/>
		</div>
	);
}