import {makeRequest} from "../apiRequest";
import {RegStateType} from "../../pages/register-race/additional/types";

export type RacesItem = {
	id: number,
	name: string,
	description: string,
	start_date: Date,
	end_date: Date,
	deadline: Date,
	logo: string | null,
	banner: string | null,
	steps: number,
	payment: boolean,
	method: string,
	stepsRemaining?: number,
}

export type RaceReg = {
	id: number,
	name: string,
	description: string,
	start_date: Date,
	end_date: Date,
	deadline: Date,
	logo: string | null,
	banner: string | null,
	banner2: string | null,
	banner3: string | null,
	steps: number,
	transfer: boolean,
	paypal: boolean,
	gcash: boolean,
	packages: Package[]
}


export type Package = {
	id: number,
	name: string,
	cost: number
}

export async function getMyRaces(): Promise<RacesItem[]>{
	const response = await makeRequest("races/my");
	if(!response.hasOwnProperty("auth") || !response.auth || !response.hasOwnProperty("races") ) return [];
	return prepareRacesList(response.races);
}

export async function getNewRaces(): Promise<RacesItem[]>{
	const response = await makeRequest("races/new");
	if(!response.hasOwnProperty("auth") || !response.auth || !response.hasOwnProperty("races") ) return [];
	return prepareRacesList(response.races);
}

export async function getRaceReg(id: string | undefined): Promise<RaceReg | null>{
	if(!id) return null;
	const response = await makeRequest(`races/get/${id}`);
	if(!response.hasOwnProperty("auth") || !response.auth || !response.hasOwnProperty("id") ) return null;
	return prepareRaceReg(response);
}

export async function reg(race: number, value: RegStateType): Promise<any>{
	const formData = new FormData();
	formData.append('File', value.screenshot);

	const data = {
		race: race,
		package: value.packageId,
		adults: value.participants.adults,
		kids: value.participants.kids,
		ship: value.ship,
		withInMetro: value.withInMetro,
		shipAddress: value.shipAddress,
		print: value.print,
		printText: value.printText,
		payment: value.paymentMethod,
		sender: value.senderName
	}

	formData.append('data', JSON.stringify(data));

	const response = await makeRequest('races/reg/', formData, true);
	if(!response.hasOwnProperty("success") ) return null;
	return response;
}

function prepareRacesList(races: any[]): RacesItem[] {
	return races.map((race) => {
		return {
			id: parseInt(race['id']),
			name: race.name,
			description: race.description,
			start_date: new Date(race.start_date),
			end_date: new Date(race.end_date),
			deadline: new Date(race.deadline),
			logo: (race['logo'] ? race['logo'] : null),
			banner: (race['banner'] ? race['banner'] : null),
			steps: parseInt(race['steps']),
			payment: race['payment'] === "1",
			method: race['method'],
			stepsRemaining: race['stepsRemaining'] ? parseInt(race['stepsRemaining']) : undefined,
		}
	});
}

function prepareRaceReg(response: any): RaceReg {
	return {
		id: parseInt(response['id']),
		name: response.name,
		description: response.description,
		start_date: new Date(response.start_date),
		end_date: new Date(response.end_date),
		deadline: new Date(response.deadline),
		logo: (response['logo'] ? response['logo'] : null),
		banner: (response['banner'] ? response['banner'] : null),
		banner2: (response['banner2'] ? response['banner2'] : null),
		banner3: (response['banner3'] ? response['banner3'] : null),
		steps: parseInt(response['steps']),
		transfer: response['transfer'] === "1",
		paypal: response['paypal'] === "1",
		gcash: response['gcash'] === "1",
		packages: preparePackagesList(response['packages'])
	}
}

function preparePackagesList(packages: any[]): Package[] {
	return packages.map((pack) => {
		return {
			id: parseInt(pack['id']),
			name: pack['name'],
			cost: parseInt(pack['cost']),
		}
	});
}