import React from "react";
import './style.css';
import Menu from "../menu/menu";
import Footer from "../footer/footer";

type Props = {
	children?: React.ReactNode;
}

export const Wrapper: React.FC<Props> = ({
	children
}) => {
	return (
		<>
			<Menu/>
			<div className={"wrapper"}>
				{children}
			</div>
			<Footer/>
		</>
	);
}