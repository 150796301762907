import {useEffect, useState} from "react";

const useFetch = (
	method: (...args: any[]) => Promise<any>,
	initial: any,
	...args: any[]
) => {
	const [result, setResult] = useState<any>(initial);

	useEffect(() => {
		method(...args).then((response) => {
			setResult(response);
		});
	}, [method]);

	return result;
}

export default useFetch
