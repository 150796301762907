import {ProgressBar} from "../../components/progress";
import {RowTitle} from "../../components/row-title/title";
import {FormWrapperMargin} from "../../components/form/wrapper/wrapperMargin";
import {Button} from "../../components/form/buttons/button";
import {FormButtons} from "../../components/form/buttons/box";
import {RaceReg, reg} from "../../controllers/api/races";
import {RegStateType} from "./additional/types";
import React from "react";
import {TotalCost} from "./additional/cost";
import {formatDate} from "../../controllers/date";
import useNotify from "../../hooks/useNotify";

type Props = {
	race: RaceReg,
	state: RegStateType,
	changeStep: (step: number) => void,
}

export const RegisterRaceStep4: React.FC<Props> = ({
	race,
	state,
	changeStep
}) => {
	const notify = useNotify();

	async function submit() {
		const response = await reg(race.id, state);
		if(!response){
			notify.warning("Something went wrong");
			return;
		}

		if (response.hasOwnProperty("success") && response['success']) {
			changeStep(5);
			return;
		}

		if(response.hasOwnProperty("error")){
			notify.warning(response.error);
			return;
		}

		notify.warning("Something went wrong");
		return;
	}

	const pack = race.packages.find((pack) => pack.id === state.packageId);
	const packName = pack ? pack.name : ""
	const adults = state.participants.adults.length
	const kids = state.participants.kids.length

	let paymentMethod = '';
	if(state.paymentMethod === 1) paymentMethod = 'Bank Transfer';
	if(state.paymentMethod === 2) paymentMethod = 'Paypal';
	if(state.paymentMethod === 3) paymentMethod = 'GCash';

	return (
		<div className={"container"}>
			<ProgressBar current={4} steps={4}/>
			<FormWrapperMargin>
				<RowTitle text={"Review Package"} icon={'payment'}/>
			</FormWrapperMargin>
			<FormWrapperMargin>
				<div className={"review-box"}>
					<div className={"section"}>
						<div className={"item"}>
							<p className={"label"}>Choose your pack</p>
							<p className={"value"}>{packName}</p>
						</div>
						<div className={"item"}>
							<p className={"label"}>Adults</p>
							<p className={"value"}>{adults}</p>
						</div>
						<div className={"item"}>
							<p className={"label"}>Kids</p>
							<p className={"value"}>{kids}</p>
						</div>
					</div>
					{
						state.participants.adults.map((person, index) => (
							<React.Fragment key={index}>
								<div className={"title"}>
									ADULT {index+1}
								</div>
								<div className={"section"}>
									<div className={"item"}>
										<p className={"label"}>Name</p>
										<p className={"value"}>{person.name}</p>
									</div>
									<div className={"item"}>
										<p className={"label"}>Birthdate</p>
										<p className={"value"}>{formatDate(person.birthdate)}</p>
									</div>
									<div className={"item"}>
										<p className={"label"}>City/Province</p>
										<p className={"value"}>{person.city}</p>
									</div>
									<div className={"item"}>
										<p className={"label"}>Mobile no.</p>
										<p className={"value"}>{person.phone}</p>
									</div>
									<div className={"item"}>
										<p className={"label"}>Shirt size</p>
										<p className={"value"}>{person.shirtSize}</p>
									</div>
								</div>
							</React.Fragment>
						))
					}
					{
						state.participants.kids.map((person, index) => (
							<React.Fragment key={index}>
								<div className={"title"}>
									KID {index+1}
								</div>
								<div className={"section"}>
									<div className={"item"}>
										<p className={"label"}>Name</p>
										<p className={"value"}>{person.name}</p>
									</div>
									<div className={"item"}>
										<p className={"label"}>Birthdate</p>
										<p className={"value"}>{formatDate(person.birthdate)}</p>
									</div>
									<div className={"item"}>
										<p className={"label"}>Shirt size</p>
										<p className={"value"}>{person.shirtSize}</p>
									</div>
								</div>
							</React.Fragment>
						))
					}
					<div className={"title"}>
						PAYMENT DETAILS
					</div>
					<div className={"section"}>
						<div className={"item"}>
							<p className={"label"}>Method</p>
							<p className={"value"}>{paymentMethod}</p>
						</div>
						{
							state.paymentMethod === 3 && (
								<div className={"item"}>
									<p className={"label"}>Account Name</p>
									<p className={"value"}>{state.senderName}</p>
								</div>
							)
						}
					</div>
				</div>
			</FormWrapperMargin>
			<TotalCost race={race} value={state}/>
			<FormButtons>
				<Button label={"BACK"} handler={() => changeStep(3)}/>
				<Button label={"SUBMIT"} type={"primary"} handler={submit}/>
			</FormButtons>
		</div>
	);
}