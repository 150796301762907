import '../style.css';
import React from "react";

type Props = {
	text: string
}

export const Total: React.FC<Props> = ({
	text
}) => {
	return (
		<div className={'text-total'}>
			{text}
		</div>
	);
}