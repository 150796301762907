import {ProgressBar} from "../../components/progress";
import {Button} from "../../components/form/buttons/button";
import {FormButtons} from "../../components/form/buttons/box";
import {RaceReg} from "../../controllers/api/races";
import React, {useState} from "react";
import useNotify from "../../hooks/useNotify";
import {Adult} from "./step2/adult";
import {AdultParticipateType, KidParticipateType, RegStateType} from "./additional/types";
import {Kid} from "./step2/kid";
import {Ship} from "./step2/ship";
import {Print} from "./step2/print";
import {TotalCost} from "./additional/cost";
// @ts-ignore
import {Lightbox} from "react-modal-image";

type Props = {
	race: RaceReg,
	state: RegStateType,
	changeState: (value: RegStateType | ((prevState: RegStateType) => RegStateType)) => void,
	changeStep: (step: number) => void,
}

export const RegisterRaceStep2: React.FC<Props> = ({
   race,
   state,
   changeState,
   changeStep
}) => {
	const notify = useNotify();
	const [popup, setPopup] = useState<"adult" | "kid" | null>(null);

	function nextStep(){
		let filled = true;

		state.participants.adults.forEach((person) => {
			if(!person.name || !person.birthdate || !person.city || !person.phone) filled = false;
		});

		state.participants.kids.forEach((person) => {
			if(!person.name || !person.birthdate) filled = false;
		});

		if(!filled){
			notify.warning("Please fill in all fields");
			return;
		}

		if(state.ship && !state.shipAddress){
			notify.warning("Please fill in the delivery address");
			return;
		}

		if(state.print && !state.printText){
			notify.warning("Please fill in your family name");
			return;
		}

		changeStep(3);
	}

	function updateAdult(index: number, value: AdultParticipateType){
		changeState((prev) => {
			let participants = {...prev.participants};
			participants.adults[index] = value;
			return {...prev, ...{participants: participants}};
		});
	}

	function updateKid(index: number, value: KidParticipateType){
		changeState((prev) => {
			let participants = {...prev.participants};
			participants.kids[index] = value;
			return {...prev, ...{participants: participants}};
		});
	}

	return (
		<div className={"container"}>
			<ProgressBar current={2} steps={4}/>
			{
				state.participants.adults.map((adult, index) =>
					<Adult
						key={index}
						num={index}
						value={adult}
						changeValue={updateAdult}
						openPopup={setPopup}
					/>
				)
			}
			{
				state.participants.kids.map((kid, index) =>
					<Kid
						key={index}
						num={index}
						value={kid}
						changeValue={updateKid}
						openPopup={setPopup}
					/>
				)
			}
			<Ship value={state} changeState={changeState}/>
			{/*<Print value={state} changeState={changeState}/>*/}
			<TotalCost race={race} value={state}/>
			<FormButtons>
				<Button label={"BACK"} handler={() => changeStep(1)}/>
				<Button label={"NEXT"} type={"primary"} handler={nextStep}/>
			</FormButtons>

			{
				popup === "adult" && (
					<Lightbox
						medium={"/images/adult_size_chart.png"}
						alt="Shirt sizes guide"
						hideZoom={true}
						onClose={() => setPopup(null)}
					/>
				)
			}
			{
				popup === "kid" && (
					<Lightbox
						medium={"/images/kid_size_chart.png"}
						alt="Shirt sizes guide"
						hideZoom={true}
						onClose={() => setPopup(null)}
					/>
				)
			}
		</div>
	);
}