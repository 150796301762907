import "./style.css";
import {Wrapper} from "../../components/wrapper/wrapper";
import {RowTitle} from "../../components/row-title/title";
import {Input} from "../../components/form/input/input";
import {Button} from "../../components/form/buttons/button";
import {FormButtons} from "../../components/form/buttons/box";
import {FormWrapper} from "../../components/form/wrapper/wrapper";
import {useContext, useState} from "react";
import {DeviceTypeContext} from "../../App";
import logo from "../../assets/logo-dark.svg";
import useNotify from "../../hooks/useNotify";
import {reg} from "../../controllers/api/user";
import {getUser} from "../../redux/reducers/account";
import {useAppDispatch} from "../../redux/hooks";

export default function Reg(){
	const dispatch = useAppDispatch();
	const notify = useNotify();
	const isMobile = useContext(DeviceTypeContext) === "mobile";

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	async function submit() {
		if (name.length === 0 || email.length === 0 || password.length === 0) {
			notify.warning("Fill in all fields");
			return;
		}

		if(password.length < 6){
			notify.warning("Password must be at least 6 characters");
			return;
		}

		const response = await reg(name, email, password);
		if (!response.hasOwnProperty("success")) {
			notify.warning("Something went wrong");
			return;
		}

		if (!response.success) {
			notify.warning(response.error);
			return;
		}

		dispatch(getUser());
	}

	if(isMobile) {
		return (
			<Wrapper>
				<div className={"container"}>
					<RowTitle text={"Sign up here"} icon={"user"} type={"tab"}/>
					<FormWrapper>
						<Input value={name} onChange={setName} placeholder={"Enter name"} label={"Name"}/>
						<Input value={email} onChange={setEmail} placeholder={"Enter email"} label={"Email"} type={"email"}/>
						<Input value={password} onChange={setPassword} placeholder={"Enter password"} label={"Password"} type={"password"}/>
					</FormWrapper>
					<FormButtons>
						<Button label={"BACK"} link={"/login"}/>
						<Button label={"submit"} type={"primary"} handler={submit}/>
					</FormButtons>
				</div>
			</Wrapper>
		);
	}

	return (
		<div className={"login"}>
			<div className={"content"}>
				<img src={logo} alt="logo" className={"logo"} />
				<p>Sign up here</p>
				<Input value={name} onChange={setName} placeholder={"Enter name"} label={"Name"} margin={"mini"}/>
				<Input value={email} onChange={setEmail} placeholder={"Enter email"} label={"Email"} type={"email"} margin={"mini"}/>
				<Input value={password} onChange={setPassword} placeholder={"Enter password"} label={"Password"} type={"password"} margin={"mini"}/>
				<Button label={"submit"} type={"primary"} handler={submit}/>
				<Button label={"BACK"}  link={"/login"}/>
			</div>
		</div>
	)
}