import "./style.css";
import logo from "../../assets/logo-dark.svg";
import {Input} from "../../components/form/input/input";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {getUser, selectAccount} from "../../redux/reducers/account";
import { Link } from "react-router-dom";
import {useState} from "react";
import {login} from "../../controllers/api/user";
import useNotify from "../../hooks/useNotify";

export default function Auth(){
	const dispatch = useAppDispatch();
	const account = useAppSelector(selectAccount);
	const notify = useNotify();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	async function loginHandler(){
		if(email.length === 0 || password.length === 0){
			notify.warning("Fill in all fields");
			return;
		}

		const response = await login(email, password);
		if(!response.hasOwnProperty("success")){
			notify.warning("Something went wrong");
			return;
		}

		if(!response.success){
			notify.warning(response.error);
			return;
		}

		dispatch(getUser());
	}

	return (
		<div className={"login"}>
			<div className={"content"}>
				<img src={logo} alt="logo" className={"logo"} />
				<p>Sign in to your account</p>
				<a href={account.facebook_link || ""} className={"btn-facebook"}>
					<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="24" height="24" transform="translate(0.5)" fill="#1877F2"/>
						<path d="M24 12.0699C24 5.7186 18.8513 0.56988 12.5 0.56988C6.14872 0.56988 1 5.7186 1 12.0699C1 17.8099 5.20538 22.5674 10.7031 23.4302V15.3941H7.7832V12.0699H10.7031V9.53629C10.7031 6.6541 12.42 5.06207 15.0468 5.06207C16.305 5.06207 17.6211 5.28668 17.6211 5.28668V8.11675H16.171C14.7424 8.11675 14.2969 9.00322 14.2969 9.91266V12.0699H17.4863L16.9765 15.3941H14.2969V23.4302C19.7946 22.5674 24 17.8099 24 12.0699Z" fill="white"/>
					</svg>
					Sign In with Facebook
				</a>
				<a href={account.google_link || ""} className={'btn-google'}>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M23.04 12.2615C23.04 11.446 22.9668 10.6619 22.8309 9.90918H12V14.3576H18.1891C17.9225 15.7951 17.1123 17.013 15.8943 17.8285V20.714H19.6109C21.7855 18.7119 23.04 15.7637 23.04 12.2615Z" fill="#4285F4"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M12 23.4998C15.105 23.4998 17.7081 22.47 19.6109 20.7137L15.8943 17.8282C14.8645 18.5182 13.5472 18.9259 12 18.9259C9.00474 18.9259 6.46951 16.903 5.56519 14.1848H1.72314V17.1644C3.61542 20.9228 7.50451 23.4998 12 23.4998Z" fill="#34A853"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M5.56523 14.185C5.33523 13.495 5.20455 12.7579 5.20455 12C5.20455 11.242 5.33523 10.505 5.56523 9.81499V6.83545H1.72318C0.944318 8.38795 0.5 10.1443 0.5 12C0.5 13.8557 0.944318 15.612 1.72318 17.1645L5.56523 14.185Z" fill="#FBBC05"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M12 5.07386C13.6884 5.07386 15.2043 5.65409 16.3961 6.79364L19.6945 3.49523C17.7029 1.63955 15.0997 0.5 12 0.5C7.50451 0.5 3.61542 3.07705 1.72314 6.83545L5.56519 9.815C6.46951 7.09682 9.00474 5.07386 12 5.07386Z" fill="#EA4335"/>
					</svg>
					Sign In with Google
				</a>
				<div className={"separator"}><span>OR</span></div>
				<Input value={email} onChange={setEmail} placeholder={"Email"} margin={"mini"}/>
				<Input value={password} onChange={setPassword} placeholder={"Password"} type={"password"} margin={"mini"}/>
				<div className={"forgot"}>
					<Link to="/forgot-password">Forgot Password?</Link>
				</div>
				<div className={"submit"} onClick={loginHandler}>LOGIN</div>
				<div className={"reg"}>
					Don't have an account?
					<Link to="/registration">Sign up here</Link>
				</div>
			</div>
		</div>
	);
}