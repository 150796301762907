import {ProgressBar} from "../../components/progress";
import {RowTitle} from "../../components/row-title/title";
import {Selector, SelectorItemType} from "../../components/form/selector/input";
import {InputNumber} from "../../components/form/number/input";
import {FormWrapperMargin} from "../../components/form/wrapper/wrapperMargin";
import {Button} from "../../components/form/buttons/button";
import {FormButtons} from "../../components/form/buttons/box";
import React from "react";
import {RaceReg} from "../../controllers/api/races";
import useNotify from "../../hooks/useNotify";
import {adultParticipateInitial, kidParticipateInitial, RegStateType} from "./additional/types";

type Props = {
	race: RaceReg,
	state: RegStateType,
	changeState: (value: RegStateType | ((prevState: RegStateType) => RegStateType)) => void,
	changeStep: (step: number) => void,
}

export const RegisterRaceStep1: React.FC<Props> = ({
	race,
	state,
	changeState,
	changeStep
}) => {
	const notify = useNotify();

	const items: SelectorItemType[] = race.packages.map((pack) => {
		return {
			key: pack.id,
			value: pack.name,
			subText: `Php${pack.cost} each`
		}
	});

	function nextStep(){
		if(state.packageId === null){
			notify.warning("Choose your pack");
			return;
		}

		const adults = Array(state.adults).fill(adultParticipateInitial, 0, state.adults);
		const kids = Array(state.kids).fill(kidParticipateInitial, 0, state.kids);

		changeState((prev) => ({...prev, ...{
			participants: {
				adults: adults,
				kids: kids
			}
		}}));

		changeStep(2);
	}

	return (
		<div className={"container"}>
			<ProgressBar current={1} steps={4}/>
			<FormWrapperMargin>
				<RowTitle text={"Choose your pack"} icon={"race"}/>
				<p className={"descriptionText"}>Your chosen category will determine the entitlements you will receive.</p>
				<Selector
					type={"large"}
					items={items}
					value={state.packageId}
					onChange={(value) => {
						changeState((prev) => ({...prev, ...{packageId: value}}))
					}}
				/>
				<RowTitle text={"Form your Team"} icon={"family"}/>
				<InputNumber
					label={"ADULTS"}
					subText={"18 years old and above"}
					value={state.adults}
					onChange={
						(value) => {
							if(value > 0) changeState((prev) => ({...prev, ...{adults: value}}))
						}
					}/>

				<InputNumber
					label={"KIDS"}
					subText={"5-17 years old"}
					value={state.kids}
					onChange={
						(value) => {
							if(value > 0) changeState((prev) => ({...prev, ...{kids: value}}))
						}
					}/>
			</FormWrapperMargin>
			<FormButtons>
				<Button label={"BACK TO HOME"} link={"/"}/>
				<Button label={"NEXT"} type={"primary"} handler={nextStep}/>
			</FormButtons>
		</div>
	);
}