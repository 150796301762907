import {RowTitle} from "../../components/row-title/title";
import {Input} from "../../components/form/input/input";
import {FormWrapper} from "../../components/form/wrapper/wrapper";
import {InputDate} from "../../components/form/date-picker/input";
import {InputFile} from "../../components/form/file-input/input";
import {FormLink} from "../../components/form/form-link/link";
import {FormButtons} from "../../components/form/buttons/box";
import {Button} from "../../components/form/buttons/button";
import {DeviceTypeContext} from "../../App";
import React, {useContext, useState} from "react";
import {addActivity, EventType} from "../../controllers/api/events";
import useNotify from "../../hooks/useNotify";

type Props = {
	race: EventType,
	update: () => void
}

type StateType = {
	activity: string,
	date: Date | null,
	steps: number | null,
	file: any
}

const initialState:StateType = {
	activity: "",
	date: null,
	steps: null,
	file: null
}
export const SubmitActivity: React.FC<Props> = ({
	race,
	update
}) => {
	const notify = useNotify();
	const isMobile = useContext(DeviceTypeContext) === "mobile";
	const [state, setState] = useState<StateType>(initialState);

	function clear(){
		setState(initialState);
	}

	async function submit() {
		if(!state.activity || !state.date || !state.steps){
			notify.warning("Please fill in all fields");
			return;
		}

		const response = await addActivity(race.participate_id, state.activity, state.date, state.steps, state.file);
		if (!response || !response.success) {
			notify.warning("Something went wrong");
			return;
		}

		update();
		clear();
	}

	return (
		<div className={"container"}>
			<RowTitle text={"Submit activity"} icon={"race"} type={"tab"}/>
			<FormWrapper>
				<Input
					label={"What type of activity did your team do?"}
					placeholder={"ex. biking, running, walking"}
					value={state.activity}
					onChange={(value) => {
						setState((prev) => ({...prev, ...{activity: value}}))
					}}
				/>
				<InputDate
					label={"When did your team do it?"}
					placeholder={"Enter date"}
					value={state.date}
					onChange={(value) => {
						setState((prev) => ({...prev, ...{date: value}}))
					}}
				/>
				<Input
					label={"How many steps did your team make?"}
					placeholder={"No. of steps"}
					value={(state.steps || "").toString()}
					onChange={(value) => {
						setState((prev) => ({...prev, ...{steps: Math.max(parseInt(value), 0)}}))
					}}
				/>
				{
					!isMobile && <FormLink link={"https://movespring.com/resources/activity-converter"} text={"STEPS CONVERTER GUIDE"}/>
				}
				<InputFile
					label={"Upload activity photo /steps tracker screenshot"}
					placeholder={"Max file size 3mb (jpg/png/gif)"}
					value={state.file}
					onChange={(value) => {
						setState((prev) => ({...prev, ...{file: value}}))
					}}
				/>
				{
					isMobile && <FormLink link={"https://movespring.com/resources/activity-converter"} text={"STEPS CONVERTER GUIDE"}/>
				}
			</FormWrapper>
			<FormButtons>
				<Button label={"clear"} handler={clear}/>
				<Button label={"submit"} type={"primary"} handler={submit}/>
			</FormButtons>
		</div>
	);
}