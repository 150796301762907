import "./style.css";
import {Wrapper} from "../../components/wrapper/wrapper";
import {RowTitle} from "../../components/row-title/title";
import {Input} from "../../components/form/input/input";
import {Button} from "../../components/form/buttons/button";
import {FormButtons} from "../../components/form/buttons/box";
import {FormWrapper} from "../../components/form/wrapper/wrapper";
import {useContext, useState} from "react";
import {DeviceTypeContext} from "../../App";
import logo from "../../assets/logo-dark.svg";
import useNotify from "../../hooks/useNotify";
import {recover} from "../../controllers/api/user";
import {useNavigate} from "react-router-dom";

export default function Recover(){
	const notify = useNotify();
	let navigate = useNavigate();
	const isMobile = useContext(DeviceTypeContext) === "mobile";

	const [email, setEmail] = useState('');

	async function submit() {
		if (email.length === 0) {
			notify.warning("Fill in email field");
			return;
		}

		const response = await recover(email);
		if (!response.hasOwnProperty("success")) {
			notify.warning("Something went wrong");
			return;
		}

		if (!response.success) {
			notify.warning(response.error);
			return;
		}

		notify.success("A new password has been sent to your email");
		navigate("/login");
	}

	if(isMobile) {
		return (
			<Wrapper>
				<div className={"container"}>
					<RowTitle text={"Recover your password"} icon={"user"} type={"tab"}/>
					<FormWrapper>
						<Input value={email} onChange={setEmail} placeholder={"Enter email"} label={"Email"} type={"email"}/>
					</FormWrapper>
					<FormButtons>
						<Button label={"BACK"} link={"/login"}/>
						<Button label={"submit"} type={"primary"} handler={submit}/>
					</FormButtons>
				</div>
			</Wrapper>
		);
	}

	return (
		<div className={"login"}>
			<div className={"content"}>
				<img src={logo} alt="logo" className={"logo"} />
				<p>Recover your password</p>
				<Input value={email} onChange={setEmail} placeholder={"Enter email"} label={"Email"} type={"email"} margin={"mini"}/>
				<Button label={"submit"} type={"primary"} handler={submit}/>
				<Button label={"BACK"} link={"/login"}/>
			</div>
		</div>
	)
}