import './style.css';
import logo from '../../assets/logo.svg'
import logoutIcon from '../../assets/logout.svg'
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {logoutAction, selectAccount} from "../../redux/reducers/account";
import {useNavigate, Link, useLocation} from "react-router-dom";
import {useContext, useState} from "react";
import {DeviceTypeContext} from "../../App";

export default function Menu(){
	const account = useAppSelector(selectAccount);
	const dispatch = useAppDispatch();
	const location = useLocation();
	let navigate = useNavigate();
	const isMobile = useContext(DeviceTypeContext) === "mobile";

	const [open, setOpen] = useState(false);

	function logoutHandler() {
		dispatch(logoutAction());
	}

	return (
		<div className={"menu"}>
			<Link to={"/"} className={"logo"}>
				<img src={logo} alt="logo" />
			</Link>
			<div className={"right"}>
				{
					(isMobile &&  account.isAuth) && (
						<div className={"burger"} onClick={() => setOpen((prev) => !prev)}>
							<div className={"line"}/>
						</div>
					)
				}
				<div className={`submenu ${open ? 'open' : ''}`}>
					{
						account.isAuth && (
							<div className={"items"}>
								<Link to={"/"} className={`item ${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
								<a href={"https://sunpiology.com/faq-virtual-race/"} className={"item"} target={"_blank"}>FAQs</a>
								<Link to={"/profile"} className={`item ${location.pathname === '/profile' ? 'active' : ''}`}>Profile</Link>
								{
									isMobile && (
										<>
											<div onClick={logoutHandler} className={'item'}>Logout</div>
										</>
									)
								}
							</div>
						)
					}
					{
						!account.isAuth && (
							<div className={"items"}>
								<div className={"item active"}>Login</div>
								<div className={"item"}>FAQs</div>
							</div>
						)
					}
					{
						account.isAuth && (
							<div className={"profile"}>
								<div className={"avatar"} onClick={() => navigate("/profile")}>
									<img src={"/images/profile.jpg"} alt={"avatar"}/>
								</div>
								<div className={"logout"}  onClick={logoutHandler}>
									<img src={logoutIcon} alt={"logout"}/>
									<span>logout</span>
								</div>
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
}