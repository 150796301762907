import '../style.css';
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../../assets/calendar.svg'

type Props = {
	label: string,
	placeholder: string,
	value: Date | null,
	onChange: (arg0: Date | null) => void
}

export const InputDate: React.FC<Props> = ({
	label,
	placeholder,
	value,
	onChange
}) => {
	const CustomInput = ({ value, onClick }: {value: string; onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}, ref: React.Ref<HTMLDivElement>) => {
		const isPlaceholder = (value === "" || value === null);
		if(isPlaceholder) value = placeholder;
		return (
			<div className={"input"} onClick={onClick} ref={ref}>
				{
					isPlaceholder ? <span className={"placeholder"}>{value}</span> : <span>{value}</span>
				}
				<img className={"icon"} src={calendar} alt={placeholder}/>
			</div>
		);
	}

	return (
		<div className={"input-block"}>
			<span className={"label"}>{label}</span>
			<DatePicker
				selected={value}
				showYearDropdown
				dropdownMode="select"
				onChange={(date: Date | null) => onChange(date)}
				customInput={React.createElement(React.forwardRef(CustomInput))}
			/>
		</div>
	);
}