import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./routes";
import "./style.css";
import AuthPage from "./routes/auth";
import RegPage from "./routes/reg";
import Recover from "./pages/auth/recover";
import {SecurityLayer} from "./components/hoc/security-layer";
import Profile from "./routes/profile";
import RaceReg from "./routes/race-reg";
import Event from "./routes/event";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route path="login" element={<SecurityLayer><AuthPage/></SecurityLayer>} />
                    <Route path="registration" element={<SecurityLayer><RegPage/></SecurityLayer>} />
                    <Route path="forgot-password" element={<SecurityLayer><Recover/></SecurityLayer>} />

                    <Route path="" element={
                        <SecurityLayer requireAuth={true}>
                            <Index/>
                        </SecurityLayer>
                    } />
                    <Route path="profile" element={
                        <SecurityLayer requireAuth={true}>
                            <Profile/>
                        </SecurityLayer>
                    } />
                    <Route path="race/:id" element={
                        <SecurityLayer requireAuth={true}>
                            <RaceReg/>
                        </SecurityLayer>
                    } />
                    <Route path="event/:id" element={
                        <SecurityLayer requireAuth={true}>
                            <Event/>
                        </SecurityLayer>
                    } />
                </Route>
            </Routes>
        </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
