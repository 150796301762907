import './style.css';
import {Button} from "../form/buttons/button";
import {RacesItem} from "../../controllers/api/races";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
	race: RacesItem,
}

export const RaceListItemReg: React.FC<Props> = ({
	race
}) => {
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	const startDate = race.start_date.toLocaleDateString('en-US', options);
	const endDate = race.end_date.toLocaleDateString('en-US', options);
	const link = `/race/${race.id}`;
	const now = (new Date()).getTime();
	const openReg = (race.deadline.getTime() > now);

	if(openReg){
		return (
			<div className={"race-list-item"}>
				<Link to={link}>
					<div className={"img"}>
						{
							race.logo && <img src={race.logo} alt={race.name}/>
						}
					</div>
				</Link>
				<Button label={"REGISTER"} link={link}/>
				<div className={"info"}>
					<p className={"title"}>{race.name}</p>
					<p className={"desc"}>{race.description}</p>
					<p className={"param"}>Start Date: {startDate}</p>
					<p className={"param"}>End Date: {endDate}</p>
				</div>
			</div>
		);
	}

	return (
		<div className={"race-list-item"}>
			<div className={"img"}>
				{
					race.logo && <img src={race.logo} alt={race.name}/>
				}
			</div>
			<div className={"info"}>
				<p className={"title"}>{race.name}</p>
				<p className={"desc"}>{race.description}</p>
				<p className={"param"}>Status: Registration ended</p>
			</div>
		</div>
	);
}