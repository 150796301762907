// @ts-ignore
import { useSnackbar } from 'snackfy';

const useNotify = () => {
	const { enqueueSnackbar } = useSnackbar();

	function success(message: string){
		enqueueSnackbar({
			message: message,
			options: {
				variant: 'success'
			}
		});
	}

	function warning(message: string){
		enqueueSnackbar({
			message: message,
			options: {
				variant: 'warning'
			}
		});
	}

	return {
		success,
		warning
	};
}

export default useNotify
