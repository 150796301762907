import {apiUrl} from "../config";

export async function makeRequest(route: string, data: any = {}, isFormData = false){
	const body = isFormData ? data : JSON.stringify(data);
	const response = await fetch(
		apiUrl + route,
		{
			method: "POST",
			credentials: 'same-origin',
			mode: 'cors',
			body: body
		}
	);

	if(response.status !== 200){
		return false;
	}

	try {
		return await response.json();
	}
	catch (e){
		return false;
	}
}