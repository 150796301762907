import {makeRequest} from "../apiRequest";

export function getInfo(){
	return makeRequest("user/info");
}

export function login(email: string, password: string){
	const data = {
		email: email,
		password: password
	}
	return makeRequest("user/login", data);
}

export function reg(name: string, email: string, password: string){
	const data = {
		name: name,
		email: email,
		password: password
	}
	return makeRequest("user/reg", data);
}

export function recover(email: string){
	const data = {
		email: email
	}
	return makeRequest("user/recover", data);
}

export function update(name: string, address: string, phone: string){
	const data = {
		name: name,
		address: address,
		phone: phone
	}
	return makeRequest("user/update", data);
}

export function logout(){
	return makeRequest("user/logout");
}
