function padTo2Digits(num: number) {
	return num.toString().padStart(2, '0');
}

export function formatDate(date: Date | null) {
	if(!date) return "";

	return [
		padTo2Digits(date.getDate()),
		padTo2Digits(date.getMonth() + 1),
		date.getFullYear(),
	].join('/');
}

export function daysRemaining(date: Date | null, date2: Date | null = null){
	if(!date) return 0;
	if(!date2) date2 = new Date();
	const diff = date.getTime() - date2.getTime();

	return Math.max(0, Math.trunc(diff / (1000 * 3600 * 24)));
}