export type RegStateType = {
	packageId: number | null,
	adults: number,
	kids: number,
	participants: {
		adults: AdultParticipateType[]
		kids: KidParticipateType[]
	},
	ship: boolean,
	withInMetro: boolean,
	shipAddress: string,
	print: boolean,
	printText: string,
	paymentMethod: number,
	screenshot: any,
	senderName: string
}

export type AdultParticipateType = {
	name: string,
	birthdate: Date | null,
	city: string,
	phone: string,
	shirtSize: string,
}

export const adultParticipateInitial = {
	name: "",
	birthdate: null,
	city: "",
	phone: "",
	shirtSize: "M"
}

export type KidParticipateType = {
	name: string,
	birthdate: Date | null,
	shirtSize: string,
}

export const kidParticipateInitial = {
	name: "",
	birthdate: null,
	shirtSize: "16"
}