import './style.css';
import {Button} from "../form/buttons/button";
import {RacesItem} from "../../controllers/api/races";
import React from "react";
import { Link } from "react-router-dom";
import {daysRemaining} from "../../controllers/date";

type Props = {
	race: RacesItem,
}

export const RaceListItem: React.FC<Props> = ({
  race
}) => {
	const link = `/event/${race.id}`;
	const now = (new Date()).getTime();

	let isActive = false;
	const stepsRemainingNum = race.stepsRemaining || 0;
	const remainingSteps = new Intl.NumberFormat("en").format(stepsRemainingNum);

	const daysToStart = daysRemaining(race.start_date);
	const daysLabel = daysToStart === 1 ? "day" : "days";
	let status = `will start in ${daysToStart} ${daysLabel}`;
	if(race.start_date.getTime()  <= now){
		status = "In progress";
		isActive = true
	}
	if(race.end_date.getTime()  <= now){
		if(stepsRemainingNum <= 0){
			status = "Finished";
		}
		else{
			status = "Unfinished";
		}
		isActive = false;
	}

	let isPayPaypal = false;
	if(!race.payment){
		isActive = false;

		if(race.method === "paypal"){
			isPayPaypal = true;
			status = "Waiting for payment";
		}
		else{
			status = "Pending";
		}
	}

	const img = (
		<div className={"img"}>
			{
				race.logo && <img src={race.logo} alt={race.name}/>
			}
		</div>
	);

	return (
		<div className={"race-list-item"}>
			{
				isActive
					? (
						<Link to={link}>
							{img}
						</Link>
					)
					:  <>{img}</>
			}
			<div className={"data"}>
				<div className={"block"}>
					<p className={"title"}>Remaining steps</p>
					<p className={"value"}>{remainingSteps}</p>
				</div>
				<div className={"block"}>
					<p className={"title"}>Status</p>
					<p className={"value"}>{status}</p>
				</div>
			</div>
			{
				isActive && (
					<Button label={"SUBMIT ACTIVITY"} link={link}/>
				)
			}
			{
				isPayPaypal && (
					<Button label={"Pay"} link={link}/>
				)
			}
		</div>
	);
}