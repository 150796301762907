import {Banner} from "../../components/banner/banner";
import {RaceData} from "../../components/race/data/block";
import {SubmitActivity} from "./submit-activity";
import React from "react";
import {confirmPayment, EventType} from "../../controllers/api/events";
import {RowTitle} from "../../components/row-title/title";
import {RunData} from "../../components/race/run-data/block";
import {PayPalButtons} from "@paypal/react-paypal-js";
import useNotify from "../../hooks/useNotify";
import {useNavigate} from "react-router-dom";

type Props = {
	race: EventType,
	update: () => void
}

export const Race: React.FC<Props> = ({
	race,
	update
}) => {
	const notify = useNotify();
	let navigate = useNavigate();
	const isPay = race.payment;
	const now = (new Date()).getTime();
	const isActive = (race.start_date.getTime() <= now && race.end_date.getTime() >= now);

	async function processPayment(order: string){
		const response = await confirmPayment(race.participate_id, order);

		if(!response){
			notify.warning("Something went wrong");
			return;
		}

		// @ts-ignore
		if (response.hasOwnProperty("success") && response['success']) {
			update();

			if(!isActive) navigate("/");
			return;
		}

		notify.warning("Something went wrong");
		return;
	}
	return (
		<>
			<Banner img={race.banner}/>
			<RaceData race={race}/>
			{
				(isPay && isActive) && (
					<>
						<SubmitActivity race={race} update={update}/>
						<div className={"container"}>
							<RowTitle text={"Run Data"} icon={"race"} type={"tab"}/>
							<RunData data={race.race_data}/>
						</div>
					</>
				)
			}
			{
				(!isPay && race.method === "paypal") && (
					<div className={"paypal-container"}>
						<PayPalButtons
							style={
								{"layout":"horizontal"}
							}
							createOrder={(data, actions) => {
								return actions.order
									.create({
										purchase_units: [
											{
												amount: {
													currency_code: "PHP",
													value: race.sum.toString(),
												},
											},
										],
									})
							}}
							onApprove={function (data, actions) {
								return processPayment(data.orderID);
							}}
						/>
					</div>
				)
			}
		</>
	);
}