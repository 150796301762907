import React from "react";
import {Total} from "../../../components/form/total";
import {RaceReg} from "../../../controllers/api/races";
import {RegStateType} from "./types";
import useRegCost from "../../../hooks/useRegCost";

type Props = {
	race: RaceReg,
	value: RegStateType
}

export const TotalCost: React.FC<Props> = ({
	race,
	value
}) => {
	const total = useRegCost(race, value);
	return (
		<Total text={`TOTAL AMOUNT: ${total}`}/>
	);
}